<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    head-row-variant="secondary"
                    :items="addresses"
                    :fields="fields"
                    @row-clicked="clicked"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                        </b-form-checkbox>
                    </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from '@/utils/TableFunctions/resizeable';
import { cellSelect } from '@/utils/TableFunctions/cellSelect';
export default {
    props: ['addresses'],
    data() {
        return {
            fields: [
                { key: 'checkbox', label: '',},
                { key: 'id', label: 'ID', sortable: true},
                { key: 'np', label: 'Населенный пункт', sortable: true},
                { key: 'number', label: 'Номер дома', sortable: true},
                { key: 'street', label: 'Улица', sortable: true},
                { key: 'korpus', label: 'Корпус', sortable: true},
                { key: 'stroenie', label: 'Строение', sortable: true},
                { key: 'vladenie', label: 'Владение', sortable: true},
                { key: 'createdLogin', label: 'Созданный логин', sortable: true},
            ]
        }
    },
    mounted() {
        resizeable();
    },
    methods: {
        clicked(item, index, event) {
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                    return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                    return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;

            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card {
        padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
    .my-card{
        overflow: auto; 
        height: 530px;
        }
}
</style>